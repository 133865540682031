import Vue from "vue";

/*TITLE*/
document.title = "Zen Sakura Villas | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Zen Sakura Villas";
Vue.prototype.$subtitle = "Villas modernas y vanguardistas, diseñadas con las últimas tecnologías de construcción.";

/*INTRO*/
Vue.prototype.$promoter = "Zen Sakura Villas";
Vue.prototype.$introSubtitle = "Villas modernas y vanguardistas, diseñadas con las últimas tecnologías de construcción.";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-urban-acero-nature--20230116090121.jpg";
Vue.prototype.$image_kitchen = "cocina-pavimento-vela-natural-frente-etimoe-land-encimera-krion-klife--20230116090116.jpg";
Vue.prototype.$image_bath1 = "banop-urban-acero-nature-urban-acero-nature-cubik-urban-acero-nature--20230116090109.jpg";
Vue.prototype.$image_bath2 = "banosec-urban-acero-nature-urban-acero-nature-cubik-urban-acero-nature--20230116090112.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-urban-acero-nature--20230116090121.jpg",
  },
  {
    src: "salon-urban-natural-nature--20230116090128.jpg",
  },
  {
    src: "salon-urban-caliza-nature--20230116090135.jpg",
  },
  {
    src: "salon-vela-natural--20230116090145.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "cocina-pavimento-vela-natural-frente-etimoe-land-encimera-krion-klife--20230116090116.jpg",
  },
  {
    src: "cocina-pavimento-urban-caliza-nature-frente-etimoe-land-encimera-krion-klife--20230116090124.jpg",
  },
  {
    src: "cocina-pavimento-urban-acero-nature-frente-blanco-nubol-encimera-krion-klife--20230116090132.jpg",
  },
  {
    src: "cocina-pavimento-urban-natural-nature-frente-blanco-nubol-encimera-krion-klife--20230116090146.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banop-urban-acero-nature-urban-acero-nature-cubik-urban-acero-nature--20230116090109.jpg",
  },
  {
    src: "banop-urban-caliza-urban-caliza-cubik-urban-caliza--20230116090130.jpg",
  },
  {
    src: "banop-velan-natural-vela-natural-limit-vela-natural--20230116090153.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banosec-urban-acero-nature-urban-acero-nature-cubik-urban-acero-nature--20230116090112.jpg",
  },
  {
    src: "banosec-urban-natural-nature-urban-natural-nature-limit-urban-natural--20230116090118.jpg",
  },
  {
    src: "banosec-urban-caliza-urban-caliza-cubik-urban-caliza--20230116090124.jpg",
  },
  {
    src: "banosec-velan-natural-vela-natural-limit-vela-natural--20230116090130.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "https://360.porcelanosapartners.com/ZEN_SAKURA/";

/*3D FILM*/
Vue.prototype.$3dFilm = "https://www.youtube.com/embed/Z1ZKoUhkyYM";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%201%20pp%20sta%20rosalia%203%2030710%20torre%20pacheco%20murcia",
    text: "Calle 1 Pp.Sta.Rosalia, 3, 30710 Torre-Pacheco, Murcia",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=calle%201%20pp%20sta%20rosalia%203%2030710%20torre%20pacheco%20murcia",
    text: "Calle 1 Pp.Sta.Rosalia, 3, 30710 Torre-Pacheco, Murcia",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:645410082",
    text: "645410082",
  },
  {
    icon: "mdi-email",
    link: "mailto:info@evoheresorts.com",
    text: "info@evoheresorts.com",
  },
];
